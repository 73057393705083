:global {
  @import "../node_modules/reset-css/sass/_reset.scss";

  body {
    font: 14px/1.5 PingFangSC-Regular, Arial, Helvetica, sans-serif;
    -webkit-tap-highlight-color: transparent;
    min-height: 100vh;
    scroll-behavior: smooth;
  }



  /*----- flex 容器 ----*/
  .flex-container {
    display: flex;
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .flex-space-around {
    justify-content: space-around;
  }

  .flex-x-center {
    justify-content: center;
  }

  .flex-y-start {
    align-items: flex-start;
  }

  .flex-x-end {
    justify-content: flex-end;
  }

  .flex-y-end {
    align-items: flex-end;
  }

  .flex-y-center {
    align-items: center;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
  }

  .layout-vertical {
    flex-direction: column;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-block {
    flex-grow: 1;
  }

  .flex-shrink {
    flex-shrink: 1;
  }

  .flex-fixed {
    flex-shrink: 0;
    flex-grow: 0;
  }

  a {
    text-decoration: none;
    color: #333;
  }

  .text-center {
    text-align: center;
  }

  .block-show {
    display: block;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
  }

  .container {
    margin: 0 8.333vw;
  }

  .logo {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url('/bt_logo.png') no-repeat center center;
    background-size: contain;
  }

  .tips {
    font-size: 12px;
    color: #999999;
  }

  .tools {
    position: relative;
  }

  // .page-title {
  //   font-size: 24px;
  //   color: #333333;
  //   margin: 0 auto 20px;
  //   font-weight: bold;
  // }
  // .page-title-desp {
  //   font-size: 14px;
  //   color: #999;
  //   font-weight: normal;
  // }

  .btn {
    display: inline-block;
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
    text-align: center;
    text-decoration: none;

    &.btn-register {
      background-image: linear-gradient(180deg, #FF9D6A 0%, #F46624 61%, #E36933 100%);
      border-radius: 0.555vw;
      font-size: 1.666vw;
      color: #fff;
      height: 5vw;
      line-height: 1;
    }
  }

  .sub-icon:local {
    position: absolute;
    top: calc(50% - 2.687vw);
    right: 0;
    width: 5.375vw;
    height: 5.375vw;
    background: url('/redEnvelope.png') no-repeat center center;
    background-size: contain;
    animation-name: zoomStyle;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }

  @keyframes zoomStyle { 
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.6);
    }
    100% {
      transform: scale(1.2);
    }
  }

  .form-item {
    height: 4.166vw;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.10);
    border-radius: 0.277vw;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      max-width: 100%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      appearance: none;
      border: none;
      outline: none;
      padding: 14px 0;
      font-size: 1.111vw;
      color: #333;
      background: transparent;

      &::placeholder {
        color: #999;
      }
    }

    &:hover {
      background: #fff;
      border-color: #EEEEEE;
    }


  }

  .error {
    font-size: 0.833vw;
    color: #E23237;
  }

  .success {
    color: #19bc38;
    font-size: 12px;
    padding: 5px 0;
  }

  .custom-checkbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 6px;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:checked+i {
        background-image: url('/icon_check_active.png');
      }
    }

    i {
      display: block;
      width: 12px;
      height: 12px;
      background: url('/icon_check.png') no-repeat center center;
      background-size: contain;
    }
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;

    &.icon-see {
      width: 44px;
      height: 44px;
      background: url('/icon_see.png') no-repeat center center;
      background-size: 16px 16px;

      &.active {
        background-image: url('/icon_see_active.png');
      }
    }

    &.icon-phone,
    &.icon-password,
    &.icon-very {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      flex-grow: 0;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.icon-phone {
      background-image: url('/icon_phone.png');
      background-size: 16px 16px;

    }

    &.icon-password {
      background-image: url('/icon_lock.png');
      background-size: 16px 16px;

    }

    &.icon-very {
      background-image: url('/icon_very.png');
      background-size: 16px 16px;


    }

  }

  .text-btn {
    font-size: 14px;
    color: #FF7401;
    padding: 0 20px;
    white-space: nowrap;
    flex-shrink: 0;
    line-height: 1;
    border-left: 1px solid #EEEEEE;
    display: inline-block;
    cursor: pointer;
    min-width: 80px;
    flex-shrink: 0;
    box-sizing: border-box;
    background: transparent;
    border: none;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;

    &:disabled {
      pointer-events: none;
      color: #999;
      cursor: not-allowed;
    }
  }

  .has-account {
    display: none;
    font-size: 28px;
    color: #333333;
    text-align: center;

    a {
      color: #FF8A00;
    }
  }

  @media(min-width:1080px) {
    .isMobile {
      display: none;
    }
    .wrapBg {
      position: absolute;
      top: 60px;
      right: 8.333vw;
    }
  }
  @media(max-width:1080px) {

    html,
    body {

      min-height: 100vh;
    }

    body {
      font-size: 3.2vw;
    }

    main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
    }

    .container {
      width: 100%;
    }

    .logo {
      width: 26vw;
      height: 8vw;
    }

    .tips {
      font-size: 3.2vw;
    }

    .isMobile {
      display: block;
    }

    .error {
      font-size: 3.2vw;
      padding: 0.667vw 0;
      color: #FF5959;
    }

    .text-btn {
      font-size: 3.733vw;
      padding: 0 2.667vw;
      min-width: 10.667vw;
    }

    .btn {
      &.btn-register {
        background-image: linear-gradient(180deg, #FF9D6A 0%, #F46624 61%, #E36933 100%);
        border-radius: 1.066vw;
        font-size: 4.8vw;
        height: 11.734vw;
      }
    }

    .wrapBg {
      background: #162b4c;
    }

    .sub-icon:local  {
      position: absolute;
      top: calc(50% - 11vw);
      right: 0;
      width: 22vw;
      height: 22vw;
      background: url('/redEnvelope.png') no-repeat center center;
      background-size: contain;
      animation-name: zoomStyle;
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }

    @keyframes zoomStyle { 
      0% {
        transform: scale(.95);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(.95);
      }
    }

    // .page-title {
    //   font-size: 6.4vw;
    //   margin-bottom: 6.4vw;
    // }
    
    // .item:nth-child(3) .form-item {
    //   background: unset;
    //   border: none;

    //   i {
    //     background: #fff;
    //   }

    //   input {
    //     background: #fff;
    //   }
    // }

    .form-item {
      height: 11.734vw;
      border-radius: 1.066vw;
      background: #FAFAFA;
      border: 1px solid #FAFAFA;
      box-shadow: none;



      input {
        font-size: 3.2vw;
        padding: 0 1.867vw 0 0;
      }
    }

    .custom-checkbox {
      margin-right: 1.333vw;

      i {
        width: 3.2vw;
        height: 3.2vw;
      }
    }

    .icon {
      &.icon-see {
        width: 12.267vw;
        height: 10.4vw;
        background-size: 4.267vw 4.267vw;
      }

      &.icon-phone,
      &.icon-password,
      &.icon-very {
        width: 10.4vw;
        height: 10.4vw;
        background-size: 4.267vw 4.267vw;
      }
    }

    .tools {
      position: relative;
    }

    .has-account {
      display: block;
      font-size: 3.733vw;
      margin-top: 8vw;
    }
  }
}