.content {
    width: 800px;
    margin: 40px auto;
    font-size: 14px;
    line-height: 1.875;

    h2 {
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 18px;
        text-align: center;
    }

    h3 {
        font-weight: bold;
        margin: 10px 0 5px;
    }

    p {
        margin-bottom: 20px;
    }
}

@media(max-width:1080px) {
    .content {
        width: 100%;
        box-sizing: border-box;
        margin: 0 4vw;
        font-size: 3.2vw;
    }

    h2 {
        font-size: 3vw;
        margin-bottom: 5vw;
    }
}